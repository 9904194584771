<template>
    <div class="bg-white w-full">
        <AlertBanner :page="doctorPage" :slug="doctorSlug"/>
    </div>
</template>

<script>
import AlertBanner from '../../../views/components/shop/AlertBanners.vue';


export default {
    components: {
        AlertBanner
    },
    data: () => ({
        doctorSlug: "",
        doctorPage: ""
    }),
    methods: {
        getPage(pageName) {
            switch (pageName) {
                case "DoctorOrderCenter":
                    this.doctorPage = "homepage"
                    break;
                case "DoctorOrderCenterCategoryPage":
                    this.doctorPage = "category"
                    break;
                // case "DoctorOrderCenterCategory_Page":
                //     this.doctorPage = "category"
                //     break;
                case "DoctorOrderCenterBrandPage":
                    this.doctorPage = "brand"
                    break;
                case "DoctorProductDetail":
                    this.doctorPage = "productDetail"
                    break;
                case "DoctorProductCheckout":
                    this.doctorPage = "checkout"
                    break;
                default:
                    break;
            }
        }
    },
    watch: {
        "$route.name": {
            handler(newVal, oldVal) {
                if(newVal !== oldVal){
                    this.getPage(this.$route.name);
                }
            },
            immediate: true
        },
        "$route.params.slug": {
            handler(newVal, oldVal) {
                if(newVal !== oldVal){
                    this.doctorSlug = newVal 
                }
            }
        }
    },
    created() {
        this.doctorSlug = this.$route.params.slug
        this.getPage(this.$route.name);
    }
}

</script>
